import React from "react"
import { graphql, Link } from "gatsby"
import dayjs from "dayjs"

import Layout from "../components/layout"
import SEO from "../components/seo"

const EngagementsPage = ({ data, pageContext, location }) => {
  const engagements = data.allWordpressWpEngagements

  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? "/engagements" : `/engagements/${currentPage - 1}`
  const nextPage = `/engagements/${currentPage + 1}`

  return (
    <Layout>
      <SEO
        title="Engagements"
        keywords={[`conductor`, `musical supervisor`, `musicologist`]}
      />
      <h3 className="font-sans font-light mb-4 text-2xl sm:text-3xl underline">
        Engagements
      </h3>
      <div className="border-2 border-purple-500 bg-purple-200 p-3 mr-1 rounded-lg shadow md:flex md:flex-wrap">
        {isFirst && (
          <>
            <h4 className="text-xl w-full mb-8">
              Upcoming engagements to be accounced soon
            </h4>
            <h4 className="underline mb-4 text-lg mt-2">
              Previous Engagements
            </h4>
          </>
        )}
        {engagements.edges.map(engagement => {
          // const showPreviousHeader =
          //   isFirst &&
          //   dayjs(engagement.node.acf.start_date, "YYYYMMDD").isBefore(
          //     new Date()
          //   )
          return (
            <Link
              key={engagement.node.slug}
              to={`/engagements/${engagement.node.slug}`}
              state={{ prevPath: location.pathname }}
              className="md:bg-purple-800 flex hover:underline md:w-full mt-1 mb-4 rounded-lg py-2 md:p-2 md:pb-2 md:mr-8"
            >
              <div className="bg-purple-300 border-2 border-purple-500 flex-1 flex rounded-lg -mt-4 md:-mr-10 shadow-lg hover:shadow-xl p-3">
                <img
                  alt=""
                  className="object-cover rounded-lg h-20 w-20 mr-2 md:m-0 m-0 self-center"
                  src={`${engagement.node.acf.image.source_url}`}
                />
                <div className="md:ml-4 no-underline">
                  <div className="font-bold text-xs tracking-wide uppercase">
                    {engagement.node.acf.artists}
                  </div>
                  <div className="font-semibold text-purple-600 leading-tight text-xs">
                    {engagement.node.acf.location}
                  </div>
                  <span
                    to={`/${engagement.node.slug}`}
                    className="block font-light mt-1 leading-tight text-lg sm:text-xl text-gray-900"
                    dangerouslySetInnerHTML={{
                      __html: engagement.node.title,
                    }}
                  />
                  <h4 className="text-sm font-semibold leading-tight mt-1">
                    {dayjs(engagement.node.acf.start_date, "YYYYMMDD").format(
                      "DD.MM.YYYY"
                    )}{" "}
                    {engagement.node.acf.end_date
                      ? `- ${dayjs(
                          engagement.node.acf.end_date,
                          "YYYYMMDD"
                        ).format("DD.MM.YYYY")}`
                      : null}
                  </h4>
                </div>
              </div>
            </Link>
          )
        })}
        <div
          className={`flex flex-1 ${
            isFirst ? "justify-end" : "justify-between"
          }`}
        >
          {!isFirst && (
            <Link to={prevPage} rel="prev">
              ← Newer Engagements
            </Link>
          )}
          {!isLast && (
            <Link to={nextPage} rel="next" className=" self-end">
              Older Engagements →
            </Link>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const engagementsQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allWordpressWpEngagements(
      limit: $limit
      skip: $skip
      sort: { fields: [acf___start_date], order: DESC }
    ) {
      edges {
        node {
          slug
          title
          acf {
            start_date
            end_date
            location
            artists
            image {
              source_url
            }
          }
        }
      }
    }
  }
`

export default EngagementsPage
